import React from "react";
import { getThreadFileURL } from "../../utils/data";

interface ThreadImageProps {
    threadNo: number;
    tim: number;
    filename?: string;
    ext?: string;
    subject?: string;
}

export const ThreadImage: React.FC<ThreadImageProps> = ({
    threadNo,
    tim,
    filename,
    ext,
    subject,
}) => {
    if (!ext) return null;

    const fileUrl = getThreadFileURL(threadNo, tim, filename || `${tim}`, ext);
    const isVideo = ext.toLowerCase() === ".webm";

    return (
        <figure className="h-[var(--thread-image-height)]">
            {isVideo ? (
                <video
                    src={fileUrl}
                    autoPlay
                    loop
                    muted
                    className="w-full h-[var(--thread-image-height)] object-cover hover:object-contain hover:h-auto transition-all duration-200 rounded-t-[inherit]"
                />
            ) : (
                <img
                    src={fileUrl}
                    alt={subject || "Thread image"}
                    className="w-full h-[var(--thread-image-height)] object-cover hover:object-contain hover:h-auto transition-all duration-200 rounded-t-[inherit]"
                    loading="lazy"
                />
            )}
        </figure>
    );
};
