import React from "react";
import { FaExternalLinkAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

interface PostLinkProps {
    href: string;
    threadNo?: string;
    children: string;
}

export const PostLink: React.FC<PostLinkProps> = ({
    href,
    threadNo,
    children,
}) => {
    const navigate = useNavigate();
    const threadMatch = href.match(/(?:\/thread\/(\d+)|#p(\d+))(?:#p(\d+))?$/);
    const targetThreadNo = threadMatch?.[1] || threadNo;
    const targetPostNo = threadMatch?.[2] || threadMatch?.[3];
    const isSameThread = threadNo === targetThreadNo;
    const isInternalThread = Boolean(threadMatch);

    if (isInternalThread || isSameThread) {
        return (
            <a
                href={`${process.env.PUBLIC_URL}/thread/${targetThreadNo}${
                    targetPostNo ? `#p${targetPostNo}` : ""
                }`}
                className="link link-primary inline-flex items-center gap-2 hover:link-info"
                onClick={(e) => {
                    e.preventDefault();
                    if (isSameThread && targetPostNo) {
                        const element = document.getElementById(
                            `p${targetPostNo}`
                        );
                        if (element) {
                            window.location.hash = `p${targetPostNo}`;
                            requestAnimationFrame(() => {
                                const elementRect =
                                    element.getBoundingClientRect();
                                const offset = elementRect.top + window.scrollY;
                                const center =
                                    offset -
                                    (window.innerHeight - elementRect.height) /
                                        2;
                                window.scrollTo({
                                    top: center,
                                    behavior: "smooth",
                                });
                            });
                        }
                    } else {
                        navigate(`/thread/${targetThreadNo}`);
                    }
                }}
            >
                {children}
                {!isSameThread && <FaExternalLinkAlt className="h-3 w-3" />}
            </a>
        );
    }

    return (
        <a
            href={href}
            className="link link-primary inline-flex items-center gap-2 hover:link-info"
            target="_blank"
            rel="noopener noreferrer"
        >
            {children}
            <FaExternalLinkAlt className="h-3 w-3" />
        </a>
    );
};
