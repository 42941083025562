import { useState, useEffect } from "react";
import { Posts, ThreadPosts } from "../utils/models";

interface ScrollToPostProps {
    threadPosts: ThreadPosts;
    isLoading: boolean;
    displayCount: number;
    setDisplayCount: (count: number) => void;
}

export const scrollToPost = (targetPostNo: string): void => {
    const element = document.getElementById(`p${targetPostNo}`);
    if (element) {
        window.location.hash = `p${targetPostNo}`;
        requestAnimationFrame(() => {
            const elementRect = element.getBoundingClientRect();
            const windowHeight = window.innerHeight;
            const elementHeight = elementRect.height;
            const offset = elementRect.top - (windowHeight - elementHeight) / 2;

            window.scrollBy({
                top: offset,
                behavior: "smooth",
            });
        });
    }
};

export const useScrollToPost = ({
    threadPosts,
    isLoading,
    displayCount,
    setDisplayCount,
}: ScrollToPostProps): void => {
    const [initialScroll, setInitialScroll] = useState(true);

    useEffect(() => {
        if (
            !isLoading &&
            threadPosts?.posts &&
            initialScroll &&
            window.location.hash
        ) {
            const targetPostNo = window.location.hash.replace("#p", "");
            const targetPost = threadPosts.posts.findIndex(
                (post) => post.no.toString() === targetPostNo
            );

            if (targetPost !== -1) {
                if (targetPost >= displayCount) {
                    setDisplayCount(targetPost + 10);
                    return;
                }

                setTimeout(() => {
                    scrollToPost(targetPostNo);
                    setInitialScroll(false);
                }, 100);
            } else {
                setInitialScroll(false);
            }
        }
    }, [isLoading, threadPosts, displayCount, initialScroll, setDisplayCount]);
};
