import React, { useMemo } from "react";
import { Gallery } from "react-grid-gallery";
import Lightbox, { Slide } from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { Post } from "../../utils/models";
import { getThreadFileURL } from "../../utils/data";
import { ThumbnailImageProps } from "react-grid-gallery";
import Video from "yet-another-react-lightbox/plugins/video";

interface GalleryImageItem {
    src: string;
    width: number;
    height: number;
    caption?: string;
    isVideo?: boolean;
}

interface VideoSlide {
    type: "video";
    sources: { src: string; type: string }[];
}

interface ThreadGalleryProps {
    posts: Post[];
    threadNo: string;
    onClose: () => void;
}

const ImageComponent = (props: ThumbnailImageProps) => {
    const { imageProps, item } = props;
    const { title, ...restImageProps } = imageProps;
    const { isVideo } = item as GalleryImageItem;

    if (isVideo) {
        return <video {...restImageProps} autoPlay muted loop playsInline />;
    }

    return (
        <img
            {...restImageProps}
            title={title || undefined}
            alt={(props.item.caption as string) || ""}
        />
    );
};

export const ThreadGallery: React.FC<ThreadGalleryProps> = ({
    posts,
    threadNo,
    onClose,
}) => {
    const [lightboxIndex, setLightboxIndex] = React.useState(-1);

    const galleryItems = useMemo(() => {
        return posts
            .filter((post) => post.filename && post.tim)
            .map((post) => {
                const src = getThreadFileURL(
                    Number(threadNo),
                    post.tim!,
                    post.filename,
                    post.ext
                );

                return {
                    src,
                    width: post.w ?? 0,
                    height: post.h ?? 0,
                    caption: post.filename ? `${post.filename}${post.ext}` : "",
                    isVideo: post.ext === ".webm",
                } as GalleryImageItem;
            });
    }, [posts, threadNo]);

    const lightboxSlides = useMemo(() => {
        return galleryItems.map((item) => {
            if (item.isVideo) {
                return {
                    ...item,
                    type: "video" as const,
                    sources: [{ src: item.src, type: "video/webm" }],
                } as VideoSlide;
            }
            return item as Slide;
        });
    }, [galleryItems]);

    const handleClick = (index: number) => {
        setLightboxIndex(index);
    };

    const handleClose = () => {
        setLightboxIndex(-1);
    };

    if (galleryItems.length === 0) {
        return null;
    }

    return (
        <div className="h-screen flex flex-col">
            <div className="flex justify-between items-center p-4 sticky top-0 bg-base-300 z-50">
                <h2 className="text-xl font-bold">Thread Media</h2>
                <button
                    onClick={onClose}
                    className="btn btn-circle btn-ghost"
                    aria-label="Close gallery"
                >
                    ✕
                </button>
            </div>
            <div className="flex-1 overflow-auto p-4">
                <Gallery
                    images={galleryItems}
                    onClick={handleClick}
                    enableImageSelection={false}
                    rowHeight={180}
                    thumbnailImageComponent={ImageComponent}
                />
                <Lightbox
                    open={lightboxIndex >= 0}
                    index={lightboxIndex}
                    close={handleClose}
                    slides={lightboxSlides}
                    plugins={[Video]}
                    video={{
                        autoPlay: true,
                        loop: true,
                        muted: true,
                    }}
                />
            </div>
        </div>
    );
};
