import React from "react";
import { PostLink } from "./PostLink";
import { PostQuote } from "./PostQuote";

interface PostContentProps {
    content: string;
    threadNo?: string;
}

const decodeHTMLEntities = (text: string): string => {
    const textarea = document.createElement("textarea");
    textarea.innerHTML = text;
    return textarea.value;
};

export const PostContent: React.FC<PostContentProps> = ({
    content,
    threadNo,
}) => {
    const decodedContent = decodeHTMLEntities(content);

    const renderContent = () => {
        const lines = decodedContent.split(/\n/g);

        return lines.map((line, lineIndex) => {
            if (!line.trim()) {
                return <br key={`empty-${lineIndex}`} />;
            }

            const parts = line.split(/(<[^>]+>)/g);

            const renderedParts = parts.map((part, index) => {
                if (part === "<br>" || part === "<br/>") {
                    return <br key={`br-${index}`} />;
                }

                if (part.startsWith("<a ")) {
                    const hrefMatch = part.match(/href="([^"]+)"/);
                    const href = hrefMatch?.[1];
                    const nextPart = parts[index + 1];

                    if (href && nextPart && !nextPart.startsWith("<")) {
                        const leadingSpace = nextPart.match(/^\s+/)?.[0] || "";
                        const trailingSpace = nextPart.match(/\s+$/)?.[0] || "";
                        const trimmedText = nextPart.trim();

                        parts[index + 1] = "";

                        return (
                            <React.Fragment key={index}>
                                {leadingSpace}
                                <PostLink href={href} threadNo={threadNo}>
                                    {trimmedText}
                                </PostLink>
                                {trailingSpace}
                            </React.Fragment>
                        );
                    }
                }

                if (part.startsWith('<span class="quote"')) {
                    const nextPart = parts[index + 1];
                    if (nextPart && !nextPart.startsWith("<")) {
                        const leadingSpace = nextPart.match(/^\s+/)?.[0] || "";
                        const trailingSpace = nextPart.match(/\s+$/)?.[0] || "";
                        const trimmedText = nextPart.trim();

                        parts[index + 1] = "";

                        return (
                            <React.Fragment key={index}>
                                {leadingSpace}
                                <PostQuote>{trimmedText}</PostQuote>
                                {trailingSpace}
                            </React.Fragment>
                        );
                    }
                }

                if (part.startsWith("</") || part.startsWith("<")) {
                    return null;
                }

                return part;
            });

            return (
                <React.Fragment key={lineIndex}>
                    {renderedParts}
                    <br key={`linebreak-${lineIndex}`} />
                </React.Fragment>
            );
        });
    };

    return (
        <div className="text-sm whitespace-normal break-words overflow-visible w-full prose prose-sm max-w-none">
            {renderContent()}
        </div>
    );
};
