import React from "react";
import { useNavigate } from "react-router-dom";
import { Post } from "../../utils/models";
import { getThreadFileURL } from "../../utils/data";
import { ThreadImage } from "./ThreadImage";
import { ThreadMetadata } from "./ThreadMetadata";

interface ThreadCardProps {
    thread: Post;
}

export const ThreadCard: React.FC<ThreadCardProps> = ({ thread }) => {
    const navigate = useNavigate();

    const decodeSubject = (subject: string | undefined): string => {
        if (!subject) return "No Subject";
        try {
            return decodeURIComponent(subject).replace(
                /&#(\d+);/g,
                (match, dec) => String.fromCharCode(dec)
            );
        } catch (error) {
            return subject.replace(/&#(\d+);/g, (match, dec) =>
                String.fromCharCode(dec)
            );
        }
    };

    const decodedSubject = decodeSubject(thread.sub);

    return (
        <div
            className="card bg-base-200 shadow-xl h-full flex flex-col hover:cursor-pointer"
            onClick={() => navigate(`/thread/${thread.no}`)}
        >
            {thread.tim && (
                <div className="flex-1 min-h-[var(--thread-image-height)] overflow-hidden rounded-t-[inherit]">
                    <ThreadImage
                        threadNo={thread.no}
                        tim={thread.tim}
                        filename={thread.filename}
                        ext={thread.ext}
                        subject={decodedSubject}
                    />
                </div>
            )}
            <div className="card-body p-4 flex flex-col">
                <div className="flex items-start mb-2">
                    <h2 className="text-lg font-semibold leading-tight flex-1">
                        {decodedSubject}
                    </h2>
                    {thread.is_non_standard && (
                        <div className="badge badge-secondary whitespace-nowrap">
                            Non-Standard
                        </div>
                    )}
                </div>
                <div className="mt-auto">
                    <ThreadMetadata
                        name={thread.name}
                        time={thread.time}
                        replies={thread.replies}
                        images={thread.images}
                        content={thread.com}
                    />
                </div>
            </div>
        </div>
    );
};
