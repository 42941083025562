import { Post } from "../../utils/models";
import { ThreadGallery } from "./ThreadGallery";
import { useEffect, useState } from "react";

interface GalleryOverlayProps {
    posts: Post[];
    threadNo: string;
    onClose: () => void;
}

export const GalleryOverlay: React.FC<GalleryOverlayProps> = ({
    posts,
    threadNo,
    onClose,
}) => (
    <div className="fixed inset-0 bg-base-300 bg-opacity-95 z-40">
        <ThreadGallery posts={posts} threadNo={threadNo} onClose={onClose} />
    </div>
);
