import React from "react";
import { FaCalendarAlt } from "react-icons/fa";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { format } from "date-fns";

interface DateRange {
    from: Date | null;
    to: Date | null;
}

interface ThreadFiltersProps {
    searchQuery: string;
    setSearchQuery: (query: string) => void;
    dateRange: DateRange | null;
    setDateRange: (range: DateRange | null) => void;
    hideNonStandardPosts: boolean;
    setHideNonStandardPosts: (hide: boolean) => void;
}

export const ThreadFilters: React.FC<ThreadFiltersProps> = ({
    searchQuery,
    setSearchQuery,
    dateRange,
    setDateRange,
    hideNonStandardPosts,
    setHideNonStandardPosts,
}) => {
    const handleCalendarClick = (inputRef: HTMLInputElement | null) => {
        inputRef?.click();
        inputRef?.showPicker?.();
    };

    const hasActiveFilters = searchQuery || dateRange || hideNonStandardPosts;

    const clearAllFilters = () => {
        setSearchQuery("");
        setDateRange(null);
        setHideNonStandardPosts(false);
    };

    return (
        <div className="flex flex-col gap-4">
            <div className="form-control">
                <div className="relative">
                    <input
                        type="text"
                        placeholder="Search threads..."
                        className="input input-bordered w-full pr-10"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    {searchQuery && (
                        <button
                            className="btn btn-ghost btn-sm absolute right-1 top-1/2 -translate-y-1/2"
                            onClick={() => setSearchQuery("")}
                        >
                            ×
                        </button>
                    )}
                </div>
            </div>

            <div className="flex gap-2">
                <div className="form-control flex-1">
                    <div className="input input-bordered w-full flex items-center">
                        <span className="flex-grow">
                            {dateRange?.from
                                ? format(dateRange.from, "yyyy-MM-dd")
                                : "From date"}
                        </span>
                        <button
                            className="btn btn-ghost btn-sm px-2"
                            onClick={() =>
                                handleCalendarClick(
                                    document.getElementById(
                                        "from-date"
                                    ) as HTMLInputElement
                                )
                            }
                        >
                            <FaCalendarAlt />
                        </button>
                        <input
                            id="from-date"
                            type="date"
                            className="absolute opacity-0 -z-10"
                            value={
                                dateRange?.from
                                    ? format(dateRange.from, "yyyy-MM-dd")
                                    : ""
                            }
                            onChange={(e) => {
                                if (e.target.value) {
                                    setDateRange({
                                        from: new Date(e.target.value),
                                        to: dateRange?.to ?? null,
                                    });
                                }
                            }}
                            max={
                                dateRange?.to
                                    ? format(dateRange.to, "yyyy-MM-dd")
                                    : undefined
                            }
                        />
                    </div>
                </div>
                <div className="form-control flex-1">
                    <div className="input input-bordered w-full flex items-center">
                        <span className="flex-grow">
                            {dateRange?.to
                                ? format(dateRange.to, "yyyy-MM-dd")
                                : "To date"}
                        </span>
                        <button
                            className="btn btn-ghost btn-sm px-2"
                            onClick={() =>
                                handleCalendarClick(
                                    document.getElementById(
                                        "to-date"
                                    ) as HTMLInputElement
                                )
                            }
                        >
                            <FaCalendarAlt />
                        </button>
                        <input
                            id="to-date"
                            type="date"
                            className="absolute opacity-0 -z-10"
                            value={
                                dateRange?.to
                                    ? format(dateRange.to, "yyyy-MM-dd")
                                    : ""
                            }
                            onChange={(e) => {
                                if (e.target.value) {
                                    setDateRange({
                                        from: dateRange?.from ?? null,
                                        to: new Date(e.target.value),
                                    });
                                }
                            }}
                            min={
                                dateRange?.from
                                    ? format(dateRange.from, "yyyy-MM-dd")
                                    : undefined
                            }
                        />
                    </div>
                </div>
                {dateRange && (
                    <button
                        className="btn btn-ghost"
                        onClick={() => setDateRange(null)}
                    >
                        Clear
                    </button>
                )}
            </div>

            <div className="form-control">
                <label className="label cursor-pointer justify-start gap-2">
                    {hideNonStandardPosts ? (
                        <MdVisibilityOff />
                    ) : (
                        <MdVisibility />
                    )}
                    <span className="label-text">
                        Hide non-standard threads
                    </span>
                    <input
                        type="checkbox"
                        className="toggle toggle-primary ml-auto"
                        checked={hideNonStandardPosts}
                        onChange={(e) =>
                            setHideNonStandardPosts(e.target.checked)
                        }
                    />
                </label>
            </div>

            {hasActiveFilters && (
                <button
                    onClick={clearAllFilters}
                    className="btn btn-outline btn-error w-full mt-2"
                >
                    Clear all filters
                </button>
            )}
        </div>
    );
};
