import { FaImages } from "react-icons/fa";

interface GalleryToggleButtonProps {
    onToggle: () => void;
}

export const GalleryToggleButton: React.FC<GalleryToggleButtonProps> = ({
    onToggle,
}) => (
    <button
        onClick={onToggle}
        className="btn btn-circle btn-primary fixed bottom-4 right-4 z-50"
        aria-label="Toggle image gallery"
    >
        <FaImages />
    </button>
);
