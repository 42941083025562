import { useState, useEffect } from "react";

export const useGallery = () => {
    const [showGallery, setShowGallery] = useState(false);

    useEffect(() => {
        const scrollbarWidth =
            window.innerWidth - document.documentElement.clientWidth;
        const bodyElement = document.body;

        if (showGallery) {
            bodyElement.classList.add("gallery-open");
            bodyElement.style.setProperty(
                "--scrollbar-compensation",
                `${scrollbarWidth}px`
            );
        } else {
            bodyElement.classList.remove("gallery-open");
            bodyElement.style.removeProperty("--scrollbar-compensation");
        }

        return () => {
            bodyElement.classList.remove("gallery-open");
            bodyElement.style.removeProperty("--scrollbar-compensation");
        };
    }, [showGallery]);

    return {
        showGallery,
        setShowGallery,
        toggleGallery: () => setShowGallery((prev) => !prev),
    };
};
