import { Link } from "react-router-dom";
import { API_BASE } from "../../config";
import { useState, useEffect } from "react";
import { FaSun, FaMoon } from "react-icons/fa";

export const NavBar: React.FC = () => {
    const [theme, setTheme] = useState("cyber4chan");

    const toggleTheme = () => {
        const newTheme =
            theme === "classic4chan" ? "cyber4chan" : "classic4chan";
        setTheme(newTheme);
        document
            .querySelector("div[data-theme]")
            ?.setAttribute("data-theme", newTheme);
    };

    return (
        <nav className="sticky top-0 z-50 bg-base-100 py-2">
            <div className="container mx-auto relative">
                <div className="navbar bg-base-300 shadow-lg rounded-box">
                    <div className="navbar-start">
                        <Link to="/" className="text-xl font-bold px-4">
                            <span className="text-primary">4GME</span> Viewer
                        </Link>
                    </div>
                    <div className="navbar-end">
                        <a
                            href={API_BASE + "../live"}
                            className="btn btn-ghost btn-sm mr-4"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Latest Live Thread
                        </a>
                        <span className="mr-2 text-sm font-medium">
                            {theme === "classic4chan" ? "Classic" : "Cyber"}
                        </span>
                        <label className="swap swap-rotate mr-4">
                            <input
                                type="checkbox"
                                checked={theme === "classic4chan"}
                                onChange={toggleTheme}
                            />
                            <FaMoon className="swap-on w-5 h-5" />
                            <FaSun className="swap-off w-5 h-5" />
                        </label>
                    </div>
                </div>
            </div>
        </nav>
    );
};
