import React, { useState, useEffect } from "react";
import { useThreads } from "../../utils/data";
import { ThreadCard } from "./ThreadCard";
import InfiniteScroll from "react-infinite-scroll-component";
import { FaExclamationTriangle, FaCalendarAlt } from "react-icons/fa";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { useScrollToTop } from "../../hooks/useScrollToTop";
import { format } from "date-fns";
import { ThreadFilters } from "./ThreadFilters";
import { IoFilterSharp } from "react-icons/io5";

interface DateRange {
    from: Date | null;
    to: Date | null;
}

export const ThreadsDisplay: React.FC = () => {
    useScrollToTop();
    const { threadFirstPosts, isLoading, isError } = useThreads();
    const [displayCount, setDisplayCount] = useState(15);
    const [searchQuery, setSearchQuery] = useState("");
    const [dateRange, setDateRange] = useState<DateRange | null>(null);
    const [showFilters, setShowFilters] = useState(false);
    const [hideNonStandardPosts, setHideNonStandardPosts] = useState(false);

    useEffect(() => {
        return () => setDisplayCount(15);
    }, []);

    const hasActiveFilters = searchQuery || dateRange || hideNonStandardPosts;

    if (isLoading)
        return (
            <div className="min-h-screen flex items-center justify-center">
                <div className="flex flex-col items-center gap-4">
                    <span className="loading loading-spinner loading-lg text-primary"></span>
                    <span className="text-lg">Loading threads...</span>
                </div>
            </div>
        );

    if (isError)
        return (
            <div className="min-h-screen flex items-center justify-center">
                <div className="alert alert-error flex items-center gap-2">
                    <FaExclamationTriangle />
                    <span>Error loading threads</span>
                </div>
            </div>
        );

    const filteredThreads = threadFirstPosts?.filter((post) => {
        const matchesStandard = !hideNonStandardPosts || !post.is_non_standard;
        const matchesSearch =
            !searchQuery ||
            post.sub?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            post.com?.toLowerCase().includes(searchQuery.toLowerCase());
        const postDate = new Date(post.time * 1000);
        const matchesDateRange =
            (!dateRange?.from || postDate >= dateRange.from) &&
            (!dateRange?.to || postDate <= dateRange.to);

        return matchesStandard && matchesSearch && matchesDateRange;
    });

    const displayedThreads = filteredThreads?.slice(0, displayCount);
    const hasMore = filteredThreads && displayCount < filteredThreads.length;

    return (
        <div className="container mx-auto p-4">
            <div className="mb-4">
                <div className="flex gap-2">
                    <button
                        onClick={() => setShowFilters(!showFilters)}
                        className="btn btn-primary flex-1 flex items-center justify-between gap-2"
                    >
                        <div className="flex items-center gap-2">
                            <IoFilterSharp />
                            <span>Filters</span>
                        </div>
                        <div className="flex items-center gap-2 text-sm opacity-80">
                            {hasActiveFilters && (
                                <span className="badge badge-secondary">
                                    Active
                                </span>
                            )}
                            <span
                                className="transition-transform duration-200"
                                style={{
                                    transform: showFilters
                                        ? "rotate(180deg)"
                                        : "rotate(0deg)",
                                }}
                            >
                                ▼
                            </span>
                        </div>
                    </button>
                </div>

                <div
                    className={`transition-all duration-200 overflow-hidden ${
                        showFilters ? "max-h-[500px] mt-4" : "max-h-0"
                    }`}
                >
                    <ThreadFilters
                        searchQuery={searchQuery}
                        setSearchQuery={setSearchQuery}
                        dateRange={dateRange}
                        setDateRange={setDateRange}
                        hideNonStandardPosts={hideNonStandardPosts}
                        setHideNonStandardPosts={setHideNonStandardPosts}
                    />
                </div>
            </div>

            <InfiniteScroll
                dataLength={displayedThreads?.length || 0}
                next={() => setDisplayCount((prev) => prev + 15)}
                hasMore={!!hasMore}
                loader={
                    <div className="flex justify-center p-4">
                        <div className="flex items-center gap-4">
                            <span className="loading loading-spinner loading-md text-primary"></span>
                            <span>Loading more...</span>
                        </div>
                    </div>
                }
            >
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4  gap-4">
                    {displayedThreads?.map((thread) => (
                        <ThreadCard key={thread.no} thread={thread} />
                    ))}
                </div>
            </InfiniteScroll>
        </div>
    );
};
