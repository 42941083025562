import React from "react";
import { FaRegCommentDots } from "react-icons/fa";
import { IoImagesOutline } from "react-icons/io5";

interface ThreadMetadataProps {
    name: string;
    time: number;
    replies?: number;
    images?: number;
    content?: string;
}

export const ThreadMetadata: React.FC<ThreadMetadataProps> = ({
    name,
    time,
    replies = 0,
    images = 0,
    content,
}) => {
    return (
        <div className="space-y-1.5">
            <div className="flex items-center space-x-3">
                <span className="font-medium text-sm">{name}</span>
                <div className="flex space-x-2">
                    <div className="badge badge-ghost gap-2 px-2.5 py-1.5 hover:bg-base-300 transition-colors cursor-default">
                        <FaRegCommentDots className="w-3.5 h-3.5" />
                        <span className="text-xs font-medium">{replies}</span>
                    </div>
                    <div className="badge badge-ghost gap-2 px-2.5 py-1.5 hover:bg-base-300 transition-colors cursor-default">
                        <IoImagesOutline className="w-3.5 h-3.5" />
                        <span className="text-xs font-medium">{images}</span>
                    </div>
                </div>
            </div>
            <time className="text-xs text-base-content/70 block">
                {new Date(time * 1000).toLocaleString()}
            </time>
        </div>
    );
};
