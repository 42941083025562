import { useMemo } from "react";
import { Post } from "../utils/models";

export const useReplyMap = (posts: Post[]) => {
    const replyMap = useMemo(() => {
        const map = new Map<number, number[]>();
        posts.forEach((post) => {
            const matches = post.com?.match(/href="#p(\d+)"/g) || [];
            matches.forEach((match) => {
                const replyTo = parseInt(match.match(/\d+/)?.[0] || "");
                if (replyTo) {
                    const replies = map.get(replyTo) || [];
                    replies.push(post.no);
                    map.set(replyTo, [...new Set(replies)]);
                }
            });
        });
        return map;
    }, [posts]);

    return replyMap;
};
