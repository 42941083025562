import React from "react";
import "./App.css";
import { useState, useEffect } from "react";

import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import { ThreadsDisplay } from "./components/Threads/ThreadsDisplay";
import axios from "axios";
import { API_BASE } from "./config";

import { ThreadDisplay } from "./components/Thread/ThreadDisplay";
import { NavBar } from "./components/NavBar/NavBar";

function Layout() {
    return (
        <>
            <NavBar />
            <main className="container mx-auto pt-2">
                <Outlet />
            </main>
        </>
    );
}

function App() {
    const [theme] = useState("cyber4chan");

    axios.defaults.baseURL = API_BASE;
    const router = createBrowserRouter(
        [
            {
                path: "/",
                element: <Layout />,
                children: [
                    {
                        path: "",
                        element: <ThreadsDisplay />,
                    },
                    {
                        path: "thread/:threadNo",
                        element: <ThreadDisplay />,
                    },
                ],
            },
        ],
        {
            basename: process.env.PUBLIC_URL,
        }
    );

    return (
        <div className="min-h-screen bg-base-100" data-theme={theme}>
            <RouterProvider router={router} />
        </div>
    );
}

export default App;
