import React, { useState, useCallback } from "react";
import ReactDOM from "react-dom";
import {
    FaFile,
    FaExternalLinkAlt,
    FaExpand,
    FaCompress,
} from "react-icons/fa";
import { getThreadFileURL } from "../../utils/data";

interface ThreadImageProps {
    threadNo: string;
    tim: number;
    filename?: string;
    ext?: string;
    w?: number;
    h?: number;
}

export const ThreadImage: React.FC<ThreadImageProps> = ({
    threadNo,
    tim,
    filename = `${tim}`,
    ext = ".jpg",
    w,
    h,
}) => {
    const [expanded, setExpanded] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [previewPosition, setPreviewPosition] = useState({ x: 0, y: 0 });
    const [imageLoaded, setImageLoaded] = useState(false);
    const [previewLoaded, setPreviewLoaded] = useState(false);
    const safeExt = ext.startsWith(".") ? ext : `.${ext}`;
    const safeFilename = filename.trim() || `${tim}`;
    const imageUrl = getThreadFileURL(
        parseInt(threadNo),
        tim,
        safeFilename,
        safeExt
    );

    const isVideo = safeExt.toLowerCase() === ".webm";

    const containerStyle = expanded
        ? { maxWidth: "100%" }
        : w && h
        ? {
              width: Math.min(w, 300),
              maxWidth: "300px",
              display: "flex",
              alignItems: "flex-start",
          }
        : {
              maxWidth: "300px",
              display: "flex",
              alignItems: "flex-start",
          };

    const handleMouseEnter = useCallback(
        (e: React.PointerEvent) => {
            if (!expanded && e.pointerType !== "touch") {
                setShowPreview(true);
            }
        },
        [expanded]
    );

    const handleMouseLeave = useCallback(() => {
        setShowPreview(false);
    }, []);

    const handleMouseMove = useCallback(
        (e: React.MouseEvent) => {
            if (!expanded) {
                setPreviewPosition({ x: e.clientX, y: e.clientY });
            }
        },
        [expanded]
    );

    const renderPreview = () => {
        if (!showPreview || expanded) return null;

        return ReactDOM.createPortal(
            <div
                className="fixed pointer-events-none"
                style={{
                    left: previewPosition.x,
                    top: previewPosition.y,
                    zIndex: 9999,
                    opacity: previewLoaded ? 1 : 0,
                    transition: "opacity 0.2s ease-in-out",
                }}
            >
                <img
                    src={imageUrl}
                    alt={safeFilename}
                    onLoad={() => setPreviewLoaded(true)}
                    className="rounded-lg shadow-lg"
                    style={{
                        maxHeight: "80vh",
                        maxWidth: "80vw",
                    }}
                />
            </div>,
            document.body
        );
    };

    return (
        <div className="flex flex-col gap-2">
            <div className="flex items-center gap-2 max-w-full overflow-hidden">
                <FaFile className="text-info flex-shrink-0" />
                <a
                    href={imageUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link link-info flex items-center gap-1 truncate"
                >
                    <span className="truncate">
                        {safeFilename}
                        {safeExt}
                    </span>
                    <FaExternalLinkAlt size={12} className="flex-shrink-0" />
                </a>
                {w && h && (
                    <span className="text-sm text-neutral-content flex-shrink-0">
                        ({w}x{h})
                    </span>
                )}
                <button
                    className="btn btn-ghost btn-xs flex-shrink-0"
                    onClick={() => setExpanded(!expanded)}
                >
                    {expanded ? <FaCompress /> : <FaExpand />}
                </button>
            </div>
            <div
                className="relative hover:cursor-zoom-in"
                onClick={() => setExpanded((v) => !v)}
                onPointerEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onMouseMove={handleMouseMove}
                style={containerStyle}
            >
                {isVideo ? (
                    <video
                        src={imageUrl}
                        controls
                        className="rounded-lg object-contain"
                        style={{ maxHeight: expanded ? "80vh" : "300px" }}
                    />
                ) : (
                    <>
                        {!imageLoaded && (
                            <div
                                className="absolute inset-0 flex justify-center items-center bg-base-200 rounded-lg"
                                style={{
                                    minHeight: "100px",
                                    minWidth: "100px",
                                }}
                            >
                                <span className="loading loading-spinner loading-md text-info"></span>
                            </div>
                        )}
                        <img
                            src={imageUrl}
                            alt={safeFilename}
                            onLoad={() => setImageLoaded(true)}
                            className={`rounded-lg object-contain ${
                                imageLoaded ? "opacity-100" : "opacity-0"
                            }`}
                            style={{
                                maxHeight: expanded ? "80vh" : "300px",
                                transition: "opacity 0.2s ease-in-out",
                            }}
                        />
                        {renderPreview()}
                    </>
                )}
            </div>
        </div>
    );
};
