import React from "react";
import { scrollToPost } from "../../hooks/useScrollToPost";
import { FaArrowTurnUp } from "react-icons/fa6";

interface PostRepliesProps {
    postNo: number;
    replyMap: Map<number, number[]>;
}

export const PostReplies: React.FC<PostRepliesProps> = ({
    postNo,
    replyMap,
}) => {
    const replies = replyMap.get(postNo) ?? [];

    if (replies.length === 0) {
        return null;
    }

    return (
        <div className="mt-4 flex flex-wrap items-center gap-3">
            <div className="badge badge-neutral gap-2">
                <FaArrowTurnUp className="h-3 w-3" />
                {replies.length} {replies.length === 1 ? "reply" : "replies"}
            </div>
            <div className="flex flex-wrap gap-1.5">
                {replies.map((replyNo) => (
                    <button
                        key={replyNo}
                        onClick={() => scrollToPost(replyNo.toString())}
                        className="badge badge-outline hover:bg-secondary hover:text-secondary-content hover:border-secondary transition-colors"
                    >
                        {replyNo}
                    </button>
                ))}
            </div>
        </div>
    );
};
