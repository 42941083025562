import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { useThread } from "../../utils/data";
import { useHighlightedPost } from "../../hooks/useHighlightedPost";
import { useReplyMap } from "../../hooks/useReplyMap";

import { ThreadImage } from "./ThreadImage";
import { PostContent } from "./PostContent";
import { useScrollToPost } from "../../hooks/useScrollToPost";
import { FaExclamationTriangle, FaImages } from "react-icons/fa";
import { PostReplies } from "./PostReplies";
import { useScrollToTop } from "../../hooks/useScrollToTop";
import { ThreadGallery } from "./ThreadGallery";
import { GalleryToggleButton } from "./GalleryToggleButton";
import { useGallery } from "../../hooks/useGallery";
import { GalleryOverlay } from "./GalleryOverlay";

export const ThreadDisplay: React.FC = () => {
    const { threadNo } = useParams<{ threadNo: string }>();
    const [displayCount, setDisplayCount] = useState(10);
    const { highlightedPost } = useHighlightedPost();
    const { showGallery, toggleGallery, setShowGallery } = useGallery();

    const { threadPosts, isLoading, isError } = useThread(
        parseInt(threadNo ?? "")
    );

    const posts = useMemo(() => threadPosts?.posts ?? [], [threadPosts?.posts]);
    const replyMap = useReplyMap(posts);

    useScrollToPost({
        threadPosts,
        isLoading,
        displayCount,
        setDisplayCount,
    });

    useEffect(() => {
        return () => setDisplayCount(10);
    }, []);

    useScrollToTop();

    if (isLoading) {
        return (
            <div className="min-h-screen flex items-center justify-center p-4">
                <div className="flex flex-col items-center gap-4 text-center">
                    <span className="loading loading-spinner loading-lg text-primary" />
                    <span className="text-base sm:text-lg">
                        Loading thread {threadNo}...
                    </span>
                </div>
            </div>
        );
    }

    if (isError) {
        return (
            <div className="min-h-screen flex items-center justify-center p-4">
                <div className="alert alert-error flex items-center gap-2 text-sm sm:text-base">
                    <FaExclamationTriangle />
                    <span>Error loading thread {threadNo}</span>
                </div>
            </div>
        );
    }

    const hasMore = displayCount < posts.length;

    const fetchMoreData = () => {
        setTimeout(() => setDisplayCount((prev) => prev + 10), 500);
    };

    return (
        <>
            <GalleryToggleButton onToggle={toggleGallery} />

            {showGallery && (
                <GalleryOverlay
                    posts={posts}
                    threadNo={threadNo ?? ""}
                    onClose={() => setShowGallery(false)}
                />
            )}

            <InfiniteScroll
                dataLength={displayCount}
                next={fetchMoreData}
                hasMore={hasMore}
                loader={<div className="loading loading-spinner loading-lg" />}
                className="p-2 sm:p-4"
            >
                {posts.slice(0, displayCount).map((post, index) => (
                    <div
                        key={post.no}
                        id={`p${post.no}`}
                        className={`card bg-base-200 shadow-xl mb-2 sm:mb-4 relative z-0 ${
                            index === 0 ? "border-l-4 border-primary" : ""
                        } ${
                            highlightedPost === post.no.toString()
                                ? "border-l-4 border-secondary"
                                : ""
                        }`}
                    >
                        <div className="card-body p-3 sm:p-8">
                            <div className="flex flex-wrap items-center gap-2 text-xs sm:text-sm">
                                {post.sub && (
                                    <h3 className="text-primary font-bold truncate max-w-full">
                                        {post.sub}
                                    </h3>
                                )}
                                <span className="text-accent truncate">
                                    {post.name}
                                </span>
                                <span className="text-neutral-content truncate">
                                    {post.now}
                                </span>
                                <span className="text-neutral-content truncate">
                                    No.{post.no}
                                </span>
                            </div>

                            {post.filename && post.tim && (
                                <ThreadImage
                                    threadNo={threadNo ?? ""}
                                    tim={post.tim}
                                    filename={post.filename}
                                    ext={post.ext}
                                    w={post.w}
                                    h={post.h}
                                />
                            )}

                            {post.com && (
                                <PostContent
                                    content={post.com}
                                    threadNo={threadNo}
                                />
                            )}

                            <PostReplies postNo={post.no} replyMap={replyMap} />
                        </div>
                    </div>
                ))}
            </InfiniteScroll>
        </>
    );
};
