import { useState, useEffect } from "react";

export const useHighlightedPost = () => {
    const [highlightedPost, setHighlightedPost] = useState<string | null>(null);

    useEffect(() => {
        const handleHashChange = () => {
            const postId = window.location.hash.replace("#p", "");
            setHighlightedPost(postId);
        };

        if (window.location.hash) {
            handleHashChange();
        }

        window.addEventListener("hashchange", handleHashChange);
        return () => window.removeEventListener("hashchange", handleHashChange);
    }, []);

    return { highlightedPost };
};
